import React from 'react'
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ContactOne from "../elements/contact/ContactOne";
import {
    Row, Col, Card, CardBody, Progress, UncontrolledTooltip, Button, Alert, FormGroup,
} from 'reactstrap';
import Faqs from '../elements/faq/FaqList';
const FaqsLanding = () => {
  return (
    <div className="active-dark bg_color--ugs">
        <Helmet pageTitle="Home" />
        <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
        <Card id="faqs" className="pt-100">
            <CardBody>
                <div className="service-area creative-service-wrapper pb--120" >
                    <div className="container">
                        <div className="row">
                            <div className="flex justify-content-center col-lg-12 align-items-center">
                                <div className="section-title align-horizontal text-center mb--30 mb_sm--0">
                                    <p className="section-title">FAQs</p>
                                </div>
                            </div>
                        </div>
                        <Faqs />
                        <div className="header-btn mt-50 flex justify-content-center">
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
        <div id="contact" className="fix">
            <div className="rn-contact-area pb--120 custom_back_ground">
                <ContactOne contactImages="/assets/images/about/about-13.jpg" contactTitle="Hire Me." />
            </div>
        </div>
        <FooterTwo homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>

    </div>
  )
}

export default FaqsLanding