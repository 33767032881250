import React, { Component } from "react";
import { FiCast, FiDollarSign, FiUsers, FiMonitor } from "react-icons/fi";
import {
    Row, Col, Card, CardBody, Progress, UncontrolledTooltip, Button, Alert, FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    Spinner,
    UncontrolledAlert,
} from 'reactstrap';
const ServiceList = [
    {
        //icon: <FiCast />,
        icon: <img className="service-logo icon m-0" src={`/assets/images/gp_images/gp_wavy_check.png`} alt="Infinite"/>,
        title: 'Convenience',
        description: 'Groupay.cc simplifies the process of sharing expenses by introducing itemized tap-tap-pay tools and innovative GroupCard technology to instantly make group payments without splitting limitations. It just makes cents!'
    },
    {
        //icon: <FiDollarSign />,
        icon: <img className="service-logo icon m-0" src={`/assets/images/gp_images/gp_circle_dollar.png`} alt="Handshake"/>,
        title: 'Practicality',
        description: 'With Groupay.cc, you can instantly pay for your share of a group payment directly, guaranteeing you won’t have to keep track of IOUs or lose friends in the process. We are all about keeping the piece!'
    },
    {
        //icon: <FiUsers />,
        icon: <img className="service-logo icon m-0" src={`/assets/images/gp_images/gp_shield_checked.png`} alt="Dolla"/>,
        title: 'Security',
        description: 'Groupay.cc is committed to keeping your personal and financial data safe and secure. Our secure payment gateway uses the latest encryption technology to protect your information and comply with the highest industry standards.'
    },
    {
        //icon: <FiUsers />,
        icon: <img className="service-logo icon m-0" src={`/assets/images/gp_images/gp_thumb_up.png`} alt="Dolla"/>,
        title: 'User Experience',
        description: 'Groupay.cc is designed for social use and collaboration with friends, family, and colleagues. Users can easily split expenses for group activities without worrying about the financial logistics, making these shared experiences more enjoyable.'
    }
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-center align-items-center justify-content-center">
                                    {val.icon}
                                <div >
                                    <p className="item-title-medium text-left-important mt-10 text-blod-description">{val.title}</p>
                                    <p className="item-description-medium text-left-important mt-10 text-gray-small">{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
