import React, { Component } from "react";
import {
    Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert,
    InputGroup, InputGroupAddon, Spinner,
    UncontrolledAlert
} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { User, Mail } from 'react-feather';
import { isMobile } from 'react-device-detect';
const axios = require('axios').default;

class ContactOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            message: ''
        };
    }

    handleSendMessage = (event, { username, email, textarea }) => {
        const body = { 'type': 'contact_us', 'status': 'open', 'name': username, 'email': email, 'message': textarea };
        const headers = { 'Content-Type': 'application/json' };
        const url = '/support/contact-us';

        this.setState({ username: username, email: email, message: textarea });

        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            timeout: 10000,
            headers: headers
        });
        console.log(process.env.REACT_APP_API_BASE_URL);
        console.log(process.env.REACT_APP_API_BASE_URL + url);
        instance.post(url, body).then((response) => {
            console.log(response)
            return response;
        })
            .then(json => {
                alert('You message has been sent!');
                this.setState({ username: '', email: '', message: '' });
            })
            .catch(error => {
                alert(error.toString());
            });
    }

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12 order-2 order-lg-1">
                            <div className="form-wrapper">
                                <Row>
                                    <Col className="lg-6">
                                    <div className="section-title text-left">
                                        <div className="section-title text-center">
                                            <br />
                                            <p className="section-title text-left-important">Contact Us</p><br/>
                                            <p className="section-description text-left-important text-blod-small-description">Need help or have a question? Contact us any time.</p>
                                            <p className="section-description text-left-important text-blod-small-description">We’re here to make your Groupay.cc experience the best it can be!</p>
                                        </div>
                                    </div>
                                        <AvForm onValidSubmit={this.handleSendMessage} className="authentication-form mt-30">

                                            <Row>
                                                <Col>
                                                    <AvGroup className="">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <span className="input-group-text">
                                                                    <User className="icon-dual" />
                                                                </span>
                                                            </InputGroupAddon>
                                                            <AvInput type="text" name="username" id="username" placeholder="Write your first and last name..." value={this.state.username} required />
                                                        </InputGroup>

                                                        <AvFeedback>This field is invalid</AvFeedback>
                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvGroup className="mb-3">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <span className="input-group-text">
                                                                    <Mail className="icon-dual" />
                                                                </span>
                                                            </InputGroupAddon>
                                                            <AvInput type="email" name="email" id="email" placeholder="Write your email address..." value={this.state.email} required />
                                                        </InputGroup>
                                                        <AvFeedback>This field is invalid</AvFeedback>
                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvGroup className="">
                                                        <InputGroup>

                                                            <AvInput type="textarea" name="textarea" id="textarea" placeholder="Write your message here..." style={{lineHeight: '25px'}}value={this.state.message} />
                                                        </InputGroup>

                                                        <AvFeedback>This field is invalid</AvFeedback>
                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup className="form-group mb-0 text-center">
                                                <div className="row align-items-center justify-content-center">
                                                    <Button className="btn-default-green custom" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Send Message</Button>
                                                </div>
                                            </FormGroup>
                                        </AvForm>
                                    </Col>
                                    <Col className="lg-6 display_on_mobile">
                                        <img className="" style={{maxWidth: '800px', width: '100%', height: 'auto', objectFit: 'auto'}} src="/assets/images/gp_images/gp_contact_mockup.png" alt="GROUPAY LOGO" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;
