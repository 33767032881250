import React, { Component } from "react";
import { FiCast, FiDollarSign, FiUsers, FiMonitor } from "react-icons/fi";
import {
    UncontrolledAccordion, AccordionItem, AccordionBody, AccordionHeader
} from 'bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { useState } from "react";


const Faqs = (props) => {
    const [open, setOpen] = useState(false);
    const [FaqList, setFaqList] = useState([
        {
            title: 'What is Groupay?',
            description: 'Groupay.cc is an app that allows for people to split group expenses instantly, efficiently, and cost-effectively. Also compatible with web and Android, Groupay.cc provides the tools necessary for splitting bills at restaurants, sharing expenses with roommates, and making reservations for travel!',
            active: false
        },
        {
            title: 'How to invite people to my group?',
            description: 'Anybody can add anybody to an existing group. The group homepage has a section where members are displayed. You can select the “+” button in this section to add your friends to the group directly within the app, by using a unique invite code, or through text message and social media!',
            active: false
        },
        {
            title: 'Is it safe to use Groupay.cc?',
            description: 'Groupay.cc uses the latest encryption technology to protect your information and comply with the highest industry standards. We are committed to keeping your personal and financial data safe and secure.',
            active: false
        },
        {
            title: 'Is there a limit to how many people can be in a group?',
            description: 'The limit does not exist! The more the merrier.',
            active: false
        },
    ])

    const [FaqList2, setFaqList2] = useState([
        {
            title: 'What types of payments can I share?',
            description: 'You can share any payment you wish! There is no limit to your splitting abilities. If your payment is shared between at least one other person, your expense can be split and paid for directly in Groupay.cc! You can make payments with any bank account or credit card of choice!',
            active: false
        },
        {
            title: 'How to remove a credit card?',
            description: 'Your profile will contain your financial and personal information. Linked cards and bank accounts will be stored in the app for ease of use. You can navigate to your stored cards and bank accounts to remove a payment method at any time. Note that you will have to relink a card or bank account before splitting your next payment if you delete all payment methods.',
            active: false
        }
        /*
        {
            title: 'Is there a limit to how many people can be in a group?',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            active: false
        },
        {
            title: 'Can I customize the settings for my group?',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            active: false
        }
        */
    ])

    const handleChangeActiveFaqlist1 = (index) => {
        let shadow = JSON.parse(JSON.stringify(FaqList))
        shadow = shadow.map((item, stt) => index == stt ? {...item, active: !item.active } : {...item, active: false})
        let shadow2 = JSON.parse(JSON.stringify(FaqList2))
        setFaqList(shadow)
        shadow2 = shadow2.map((item, stt) => ({...item, active: false }))
        setFaqList2(shadow2)
    }

    const handleChangeActiveFaqlist2 = (index) => {
        let shadow = JSON.parse(JSON.stringify(FaqList2))
        shadow = shadow.map((item, stt) => index == stt ? {...item, active: !item.active } : {...item, active: false})
        setFaqList2(shadow)
        let shadow2 = JSON.parse(JSON.stringify(FaqList))
        shadow2 = shadow2.map((item, stt) => ({...item, active: false }))
        setFaqList(shadow2)
    }
    
    return (
        <React.Fragment>
            <div className="row gap-20">
            <div className="accordion custom_item" id="accordionExample">
            {FaqList.map((val, i) => (
                <div className="card" key={i}  id={`heading_${i}`}>
                    <div className="card-header" id={`heading_${i}`}>
                    <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left position-relative" type="button" onClick={() => handleChangeActiveFaqlist1(i)}>
                            <p className="item-title-medium text-left-important text-blod-description">{val.title}</p>
                            <span className={`chevron ${val.active ? 'checked': ''}`}></span>
                        </button>
                    </h2>
                    </div>

                    <Collapse in={val.active}>
                        <div id="example-collapse-text" className="card-body">
                            <p className="item-description-medium text-left-important text-gray-small">{val.description}</p>
                        </div>
                    </Collapse>
                </div>
            ))}
            </div>

            <div className="accordion custom_item" id="accordionExample2">
            {FaqList2.map((val, i) => {
                return (
                <div className="card" key={i}  id={`heading_${i}`}>
                    <div className="card-header" id={`heading_${i}`}>
                    <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left position-relative" type="button" onClick={() => handleChangeActiveFaqlist2(i)}>
                            <p className="item-title-medium text-left-important text-blod-description">{val.title}</p>
                            <span className={`chevron ${val.active ? 'checked': ''}`}></span>
                        </button>
                    </h2>
                    </div>

                    <Collapse in={val.active}>
                        <div id="example-collapse-text" className="card-body">
                            <p className="item-description-medium text-left-important text-gray-small">{val.description}</p>
                        </div>
                    </Collapse>
                </div>
            )})}
            </div>
            </div>

        </React.Fragment>
    )
}
export default Faqs;
