import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const WorksList = [
    {
        title: 'Set Up Your Account',
        description: 'You must create an account to use Groupay.cc. You can sign up using your mobile number or by linking external accounts through Google or Apple. Once you’ve set up your account, you can add your payment details to start enjoying the simplicity of making payments like never before.'
    },
    {
        title: 'Create or Join a Group',
        description: 'Groups can be created for any shared expense, including, but not limited to, restaurant outings, travel reservations, and groceries. One user creates a group and invites all members to join directly in the app, through a unique invite code, or by sending an invitation via text or social media. Each member subsequently accepts the invitation to join the group and can move forward with paying their share.'
    },
    {
        title: 'Pay Your Share',
        description: 'Within the group, you can either scan a physical receipt or manually enter the expense details to generate a digitized and itemized breakdown of costs owed. You can then tap items for which you are liable, choose to split individual items with other group members directly, and keep track of outstanding values, all while your share is calculated and updated instantly.'
    },
    {
        title: 'Pay the Bill',
        description: 'After everyone in the group has paid their share, a temporary GroupCard with unique details is generated in real-time. Once the secure payment gateway is approved, the card is ready for use and the vendor receives one form of payments that’s funded by each group member’s card or bank account of choice. You can review your transaction history at any time within the app and keep track of your expenses efficiently!'
    }
]


class WorksThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = WorksList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-left border-2px-solid-E6EAEE">
                                <div className="icon_service" style={{gap: '50px'}}>
                                    <div className="worklist_index">{i + 1}</div>
                                    <div className="text-left" style={{ width: 'calc(100% - 100px)'}}>
                                        <p className="item-title-large ">{val.title}</p>
                                        <p className="item-description-large text-gray-small">{val.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default WorksThree;
